const LogoImage: React.FCC<{
  className?: string;
}> = ({ className }) => {
  return (
    <svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 398.75 107.97" className={className ? className : `w-[95px] sm:w-[105px]`}>
      <defs>
        <style>
          {`
            .cls-1, .cls-2, .cls-3 {
              stroke-width: 0px;
            }
            .cls-2 {
              fill: #1a1a18;
            }

            .cls-3{
              fill:#7d7dff;
            }
          `}
        </style>
      </defs>
      <rect className="cls-3" width="89.83" height="89.83" rx="5" ry="5" />
      <path className="cls-2" d="M68.08,78.2c-1.11,1.67-2.4,3.06-3.86,4.17-1.46,1.11-2.92,2.02-4.38,2.71-1.46.69-2.92,1.15-4.38,1.36s-2.75.31-3.86.31c-3.41,0-6.61-.7-9.6-2.09-2.99-1.39-5.62-3.3-7.88-5.74-2.26-2.43-4.07-5.37-5.42-8.81-1.36-3.44-2.03-7.25-2.03-11.42s.63-7.68,1.88-11.16c1.25-3.48,3.01-6.47,5.27-8.97,2.26-2.5,4.92-4.47,7.98-5.89,3.06-1.42,6.5-2.14,10.33-2.14,1.18,0,2.45.12,3.81.37,1.36.24,2.66.64,3.91,1.2,1.25.56,2.47,1.3,3.65,2.24,1.18.94,2.19,2.1,3.03,3.49V10.91h18.26v69.8s.63,4.79-4.22,4.79h-12.47v-7.3ZM55.87,70.79c1.6,0,3.09-.28,4.49-.83,1.39-.56,2.57-1.37,3.55-2.45.97-1.08,1.74-2.36,2.29-3.86.56-1.49.83-3.22.83-5.16s-.28-3.65-.83-5.11c-.56-1.46-1.32-2.71-2.29-3.76-.98-1.04-2.16-1.84-3.55-2.4-1.39-.56-2.89-.83-4.49-.83-1.46,0-2.85.3-4.17.89-1.32.59-2.47,1.39-3.44,2.4-.97,1.01-1.77,2.28-2.4,3.81-.63,1.53-.94,3.23-.94,5.11s.31,3.58.94,5.11c.63,1.53,1.42,2.82,2.4,3.86.97,1.04,2.12,1.84,3.44,2.4,1.32.56,2.71.83,4.17.83Z" />
      <path className="cls-2" d="M119.17,30.52c3.82,0,7.3.59,10.43,1.77,3.13,1.18,5.84,2.85,8.14,5.01,2.29,2.16,4.08,4.78,5.37,7.88,1.29,3.1,1.93,6.45,1.93,10.07,0,1.39-.12,2.82-.37,4.28-.24,1.46-.47,2.57-.68,3.34h-34.22c.21,1.53.73,2.83,1.56,3.91.83,1.08,1.81,1.98,2.92,2.71,1.11.73,2.42,1.27,3.91,1.62,1.49.35,2.97.52,4.43.52,3.06,0,5.63-.52,7.72-1.56,2.09-1.04,3.76-2.19,5.01-3.44l9.28,11.37c-3.62,3.34-7.29,5.63-11.01,6.89-3.72,1.25-7.81,1.88-12.26,1.88s-8.76-.71-12.52-2.14c-3.76-1.42-6.92-3.39-9.49-5.89-2.57-2.5-4.59-5.48-6.05-8.92-1.46-3.44-2.19-7.18-2.19-11.21s.73-7.55,2.19-10.95c1.46-3.41,3.42-6.36,5.89-8.87,2.47-2.5,5.42-4.5,8.87-6,3.44-1.49,7.15-2.24,11.11-2.24ZM118.65,44.92c-1.11,0-2.21.17-3.29.52-1.08.35-2.03.85-2.87,1.51-.83.66-1.53,1.44-2.09,2.35-.56.9-.83,1.98-.83,3.23h17.73v-.1c0-1.25-.21-2.31-.63-3.18-.42-.87-1.04-1.63-1.88-2.3-.83-.66-1.76-1.16-2.76-1.51-1.01-.35-2.14-.52-3.39-.52Z" />
      <path className="cls-2" d="M232.12,81.82c-2.61-2.81-4.72-6.22-6.26-10.15-.06-.15-.11-.3-.17-.45-.61.06-1.15.09-1.61.09-2.02,0-3.27-.54-3.76-1.62-.49-1.08-.73-2.28-.73-3.6V10.91h-18.15v58.52c0,2.78.43,5.27,1.3,7.46.87,2.19,2.05,4,3.55,5.42,1.49,1.43,3.34,2.52,5.53,3.29,2.19.76,4.68,1.15,7.46,1.15,1.81,0,3.42-.14,4.85-.42.96-.19,1.83-.43,2.62-.71,0,0,0,0,0,0,2.08-.4,4.4-1.49,6.47-2.67-.38-.37-.75-.74-1.11-1.13Z" />
      <path className="cls-2" d="M269.18,78.2c-1.11,1.67-2.4,3.06-3.86,4.17-1.46,1.11-2.92,2.02-4.38,2.71-1.46.69-2.92,1.15-4.38,1.36s-2.75.31-3.86.31c-3.41,0-6.61-.7-9.6-2.09-2.99-1.39-5.62-3.3-7.88-5.74-2.26-2.43-4.07-5.37-5.42-8.81-1.36-3.44-2.03-7.25-2.03-11.42s.63-7.68,1.88-11.16c1.25-3.48,3.01-6.47,5.27-8.97,2.26-2.5,4.92-4.47,7.98-5.89,3.06-1.42,6.5-2.14,10.33-2.14,1.18,0,2.45.12,3.81.37,1.36.24,2.66.64,3.91,1.2,1.25.56,2.47,1.3,3.65,2.24,1.18.94,2.19,2.1,3.03,3.49V10.91h18.26v69.8s.63,4.79-4.22,4.79h-12.47v-7.3ZM256.97,70.79c1.6,0,3.09-.28,4.49-.83,1.39-.56,2.57-1.37,3.55-2.45.97-1.08,1.74-2.36,2.29-3.86.56-1.49.83-3.22.83-5.16s-.28-3.65-.83-5.11c-.56-1.46-1.32-2.71-2.29-3.76-.98-1.04-2.16-1.84-3.55-2.4-1.39-.56-2.89-.83-4.49-.83-1.46,0-2.85.3-4.17.89-1.32.59-2.47,1.39-3.44,2.4-.97,1.01-1.77,2.28-2.4,3.81-.63,1.53-.94,3.23-.94,5.11s.31,3.58.94,5.11c.63,1.53,1.42,2.82,2.4,3.86.97,1.04,2.12,1.84,3.44,2.4,1.32.56,2.71.83,4.17.83Z" />
      <path className="cls-2" d="M339.76,80.71v-2.83h0v-25.97c0-3.34-.56-6.33-1.67-8.97-1.11-2.64-2.73-4.87-4.85-6.68-2.12-1.81-4.7-3.22-7.72-4.23-3.03-1.01-6.55-1.51-10.59-1.51-2.57,0-5.03.24-7.36.73-2.33.49-4.54,1.22-6.62,2.19-2.09.98-3.93,2.1-5.53,3.39-1.6,1.29-2.99,2.66-4.17,4.12l9.49,11.37c.76-.83,1.58-1.63,2.45-2.4.87-.76,1.86-1.42,2.97-1.98,1.11-.56,2.33-1.03,3.65-1.41,1.32-.38,2.71-.57,4.17-.57,2.29,0,4.26.44,5.89,1.3,1.63.87,2.45,2.45,2.45,4.75v.1l-13.77,2.82c-5.63,1.11-9.98,2.99-13.04,5.63-3.06,2.64-4.59,5.98-4.59,10.01,0,2.37.45,4.54,1.36,6.52.9,1.98,2.19,3.69,3.86,5.11,1.67,1.43,3.56,2.54,5.69,3.34,2.12.8,4.57,1.2,7.35,1.2,3.48,0,6.38-.68,8.71-2.03,2.33-1.36,4.05-3.08,5.16-5.16v5.95h12.47c4.84,0,4.22-4.79,4.22-4.79ZM322.34,63.8c0,1.53-.19,2.9-.57,4.12-.38,1.22-.96,2.26-1.72,3.13-.77.87-1.72,1.53-2.87,1.98-1.15.45-2.42.68-3.81.68-.77,0-1.46-.1-2.09-.31-.62-.21-1.15-.5-1.56-.89-.42-.38-.75-.83-.99-1.36-.24-.52-.37-1.13-.37-1.83,0-1.25.49-2.24,1.46-2.97.97-.73,2.26-1.23,3.86-1.51l8.66-1.77v.73Z" />
      <path className="cls-2" d="M195.76,80.71v-2.83h0v-25.97c0-3.34-.56-6.33-1.67-8.97-1.11-2.64-2.73-4.87-4.85-6.68-2.12-1.81-4.7-3.22-7.72-4.23-3.03-1.01-6.55-1.51-10.59-1.51-2.57,0-5.03.24-7.36.73-2.33.49-4.54,1.22-6.62,2.19-2.09.98-3.93,2.1-5.53,3.39-1.6,1.29-2.99,2.66-4.17,4.12l9.49,11.37c.76-.83,1.58-1.63,2.45-2.4.87-.76,1.86-1.42,2.97-1.98,1.11-.56,2.33-1.03,3.65-1.41,1.32-.38,2.71-.57,4.17-.57,2.29,0,4.26.44,5.89,1.3,1.63.87,2.45,2.45,2.45,4.75v.1l-13.77,2.82c-5.63,1.11-9.98,2.99-13.04,5.63-3.06,2.64-4.59,5.98-4.59,10.01,0,2.37.45,4.54,1.36,6.52.9,1.98,2.19,3.69,3.86,5.11,1.67,1.43,3.56,2.54,5.69,3.34,2.12.8,4.57,1.2,7.35,1.2,3.48,0,6.38-.68,8.71-2.03,2.33-1.36,4.05-3.08,5.16-5.16v5.95h12.47c4.84,0,4.22-4.79,4.22-4.79ZM178.34,63.8c0,1.53-.19,2.9-.57,4.12-.38,1.22-.96,2.26-1.72,3.13-.77.87-1.72,1.53-2.87,1.98-1.15.45-2.42.68-3.81.68-.77,0-1.46-.1-2.09-.31-.62-.21-1.15-.5-1.56-.89-.42-.38-.75-.83-.99-1.36-.24-.52-.37-1.13-.37-1.83,0-1.25.49-2.24,1.46-2.97.97-.73,2.26-1.23,3.86-1.51l8.66-1.77v.73Z" />
      <path className="cls-1" d="M341.33,90.84c1.39.69,2.75,1.15,4.07,1.36,1.32.21,2.47.31,3.45.31,1.04,0,2.07-.07,3.08-.21,1.01-.14,2-.52,2.98-1.15.97-.63,1.86-1.55,2.66-2.77.8-1.22,1.48-2.84,2.04-4.85l-20.88-51.47h18.79l11.28,32.99,11.59-32.99h18.38l-23.07,56.38c-1.6,3.83-3.25,6.98-4.96,9.45-1.71,2.47-3.51,4.45-5.43,5.95-1.92,1.5-4,2.56-6.26,3.18-2.26.63-4.75.94-7.46.94-2.16,0-4.12-.24-5.9-.73-1.77-.49-3.22-1.01-4.33-1.57v-14.83Z" />
    </svg>
  );
};

export default LogoImage;
